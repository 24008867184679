import "./style.min.css";

const Loader = () => {
  return (
    <div className="containerLoader">
<div className="cup">Loading ...
  <div className="coffee"></div>
</div>
  </div>
  );
};

export default Loader;
